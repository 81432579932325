<template>
  <v-dialog :value="isShown" max-width="600px" class="white" persistent>
    <payment-manual-card
      v-if="manualDialog"
      :invoice="invoice"
      :payment-translation="paymentTranslation"
      @close-manual-dialog="closeDialog()"
    />
    <v-card v-else class="overflow-hidden">
      <v-card-title class="d-flex align-center justify-space-between secondary">
        <div class="d-flex align-center">
          <div>{{ paymentTranslation.name }}</div>
          <c-info-btn is-tooltip class="ml-2 mb-1">
            <template #tooltipText>
              {{ $t('add_funds.attention_direct_payments', { name: adNetwork.name }) }}
              {{ $t('add_funds.attention_approve') }}
            </template>
          </c-info-btn>
        </div>
        <button type="button" @click="closeDialog()">
          <v-icon class="black--text opacity-40" size="18">
            $close-outlined
          </v-icon>
        </button>
      </v-card-title>
      <template v-if="!crCodeImage">
        <v-card-text class="px-4 px-sm-5">
          <div class="d-flex flex-column align-start-1 mb-7">
            <div class="d-flex flex-column flex-sm-row flex-grow mt-6">
              <c-text-field
                :id="seleniumIds.SELENIUM_TEST_PAYMENT_FORM_AMOUNT_FIELD"
                :value="amount"
                :class="['flex-grow-1', { 'global-full-width': isMobile }]"
                :min="1"
                label-bold
                type="number"
                :height="50"
                hide-details="auto"
                :error-messages="errors.amount || errors.payment_method_id"
                @input="setAmountTotals($event)"
              >
                <template #label>
                  <div class="d-flex justify-space-between global-full-width">
                    <div class="d-flex align-center">
                      <span class="text-body-2 font-weight-medium black--text">
                        {{ $t('main.amount') }}
                      </span>
                      <c-info-btn is-tooltip class="ml-2 mb-1">
                        <template #tooltipText>
                          {{ $t('add_funds.funds_will_be_loaded') }}
                        </template>
                      </c-info-btn>
                    </div>
                    <div class="d-block d-sm-none text-body-2 info--text">
                      {{ minAddFundsAmountText }}
                    </div>
                  </div>
                </template>
              </c-text-field>

              <auto-payment-switch
                v-if="canEnableAutoPayment"
                v-model="autoPayment"
                :class="['ml-0 mt-2 mt-sm-4 ml-sm-3']"
              />
            </div>
            <div
              class="d-flex flex-column flex-sm-row flex-wrap align-center justify-space-between mt-2"
            >
              <div class="d-none d-sm-block text-body-2 info--text">
                {{ minAddFundsAmountText }}
              </div>
              <a
                v-if="!!redirectToAutoPaymentInfo && canEnableAutoPayment"
                class="text-decoration-underline"
                target="_blank"
                :href="redirectToAutoPaymentInfo"
              >
                {{ $t('add_funds.auto_payment_info') }}
              </a>
            </div>
          </div>

          <div class="mb-7">
            <charge-securion-pay-field
              v-if="autoPaymentMethod"
              :auto-payment="autoPayment"
              :is-loading="fetchIsLoading"
              :recurring-payments-customer="recurringPaymentsCustomer"
            />

            <div v-if="!!redirectToAutoPaymentInfo && autoPaymentMethod" class="d-flex justify-end">
              <a
                :class="['text-decoration-underline mt-2', { 'mx-auto': isMobile }]"
                target="_blank"
                :href="redirectToAutoPaymentInfo"
              >
                {{ $t('add_funds.auto_payment_info') }}
              </a>
            </div>
          </div>

          <promocodes
            v-model="couponCode"
            class="mb-3"
            :error-messages="errors.coupon_code"
            :totals="totals"
            :is-loading="isLoading"
            @clear-error="errors = { ...errors, coupon_code: [] }"
            @update="updateTotals()"
          />

          <totals :totals="totals" class="text-center" />

          <wire-transfer-field v-if="wireTransferTypeIsSelected" class="mb-3" />

          <div v-if="errors.payment_method">
            <errors :error-messages="errors.payment_method" />
            <router-link :to="{ name: 'Verifications', params: { type: 'card' } }">
              {{ $t('add_funds.verify_card') }}
            </router-link>
          </div>
          <div v-if="agreementCheckboxIsShown" class="d-flex align-center">
            <v-checkbox v-model="agreementConfirm" class="pa-0 ma-0" hide-details color="primary" />
            <div>
              {{ $t('add_funds.agreement.i_agree') }}
              <a
                class="info--text"
                :href="helpUrls.terms_and_conditions"
                target="_blank"
              >
                {{ $t('add_funds.agreement.terms_and_conditions') }}
              </a>
              {{ $t('add_funds.agreement.and') }}
              <a class="info--text" :href="helpUrls.privacy_policy" target="_blank">
                {{ $t('add_funds.agreement.privacy_policy') }}
              </a>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="d-flex flex-column px-8 pb-8 pt-6">
          <div class="text-uppercase text-subtitle-1 font-weight-bold mb-2">
            <span>{{ $t('add_funds.total_title') }}:</span>
            <span v-if="adNetwork.settings.currencySignIsShown">$</span>
            {{ toFixedByDefault(totals.totalAmount) }}
          </div>
          <captcha ref="captcha" :error-messages="errors['g-recaptcha-response']" @verified="submit($event)">
            <c-btn
              depressed
              large
              block
              color="primary"
              :disabled="agreementCheckboxIsShown && !agreementConfirm"
              :loading="invoicedIsLoading"
              :label="$t('add_funds.proceed_billing')"
              @click="$refs.captcha.performVerifiedAction()"
            />
          </captcha>
        </v-card-actions>
      </template>
      <div v-else class="pb-2">
        <v-img :src="crCodeImage" max-height="300" min-height="300" contain />
        <div class="text-uppercase text-center text-subtitle-1 font-weight-bold mb-2">
          <span>{{ $t('add_funds.total_title') }}:</span>
          <span v-if="adNetwork.settings.currencySignIsShown">$</span>
          {{ toFixedByDefault(totals.totalAmount) }}
        </div>
        <div class="text-center text-body-2">
          <span class="mr-4">
            <span class="font-weight-medium">{{ $t('add_funds.token') }}:</span>
            {{ invoice?.formParams?.currency }}
          </span>
          <span class="font-weight-medium">
            {{ $t('add_funds.network') }}:
          </span>
          {{ invoice?.formParams?.network }}
        </div>
        <div class="text-center font-weight-medium">
          {{ $t('add_funds.address') }}:
        </div>
        <div class="d-flex align-center justify-center px-1 text-body-2">
          <span class="address">
            {{ invoice?.formParams?.address }}
          </span>
          <copy-button :text-copied="invoice?.formParams?.address" />
        </div>
        <div class="text-center text-body-2">
          {{ $t('add_funds.you_pay_network_fee') }}
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CInfoBtn from '@clickadilla/components/ui/CInfoBtn.vue'
  import { getPaymentStrategy } from '@clickadilla/components/PaymentStrategies/index.js'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import { stringify } from 'qs'
  import paymentTypes from '@/types/payment-types.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import invoicedRepository from '@/services/repositories/invoiced-repository.js'
  import WireTransferField from './WireTransferField.vue'
  import ChargeSecurionPayField from '@/components/AddFunds/ChargeSecurionPayField.vue'
  import Promocodes from '@/views/AddFunds/PaymentMethods/PaymentForm/Promocodes.vue'
  import AutoPaymentSwitch from '@/views/AddFunds/PaymentMethods/PaymentForm/AutoPaymentSwitch.vue'
  import handleErrors from '@/services/handleErrors.js'
  import PaymentManualCard from '@/views/AddFunds/PaymentMethods/PaymentForm/PaymentManualCard.vue'
  import Totals from '@/views/AddFunds/PaymentMethods/PaymentForm/Totals.vue'
  import PaymentMethod from '@/services/classes/PaymentMethod.js'
  import routeNames from '@/types/route-names.js'
  import googleAnalytics from '@/plugins/google-analytics.js'
  import Captcha from '@/components/Captcha.vue'
  import CopyButton from '@/components/CopyButton.vue'

  const { getGoogleAnalyticsClientId, getGoogleAnalyticsSessionId } = googleAnalytics

  export default {
    name: 'PaymentForm',
    components: {
      CopyButton,
      Captcha,
      Errors,
      Totals,
      PaymentManualCard,
      CTextField,
      WireTransferField,
      ChargeSecurionPayField,
      CBtn,
      CInfoBtn,
      Promocodes,
      AutoPaymentSwitch
    },
    props: {
      paymentMethod: {
        type: Object,
        required: true
      },
      isShown: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        amount: null,
        agreementConfirm: false,
        couponCode: '',
        autoPayment: true,
        isLoading: false,
        invoicedIsLoading: false,
        errors: {},
        manualDialog: false,
        invoice: null,
        totals: {
          bonus: null,
          totalAmount: 0,
          fee: 0,
          feePercent: 0,
          coupon: {
            bonusAmount: 0,
            bonusPercentage: 0,
            bonusType: ''
          },
          couponBonus: 0
        },
        seleniumIds,
        crCodeImage: null
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork', 'minAddFundsAmount']),
      ...mapState('personal', ['fetchIsLoading', 'recurringPaymentsCustomer']),
      ...mapGetters('settings', ['helpUrls']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      agreementCheckboxIsShown() {
        return this.paymentMethod.type === paymentTypes.PAYEER
      },
      redirectToAutoPaymentInfo() {
        return this.helpUrls.auto_payment_info
      },
      canEnableAutoPayment() {
        return this.shift4Strategy && !this.autoPaymentMethod
      },
      minAmount() {
        return this.paymentMethod.minAddFundsAmount ?? this.minAddFundsAmount
      },
      minAddFundsAmountText() {
        const prefix = this.adNetwork.settings.currencySignIsShown ? '$' : ''
        return `${this.$t('add_funds.minimum_amount')} ${prefix} ${this.minAmount}`
      },
      wireTransferTypeIsSelected() {
        return this.paymentMethod.type === paymentTypes.WIRE_TRANSFER
      },
      shift4Strategy() {
        return this.paymentMethod.type === paymentTypes.SHIFT4
      },
      autoPaymentMethod() {
        return this.recurringPaymentsCustomer?.paymentMethodId === this.paymentMethod.id
      },
      paymentMethodIsManual() {
        return this.paymentMethod.type === paymentTypes.MANUAL
      },
      paymentMethodIsStripeCheckout() {
        return this.paymentMethod.type === paymentTypes.STRIPE_CHECKOUT
      },
      paymentTranslation() {
        const foundTranslationsPayment = this.paymentMethod.translations.find(
          ({ locale }) => locale === this.$i18n.locale
        )
        if (!foundTranslationsPayment) {
          const foundEnTranslations = this.paymentMethod.translations.find(
            ({ locale }) => locale === 'en'
          )
          return foundEnTranslations || {}
        }
        return foundTranslationsPayment
      },
      measurementId() {
        return this.adNetwork.googleAnalyticsMeasurementId
      }
    },
    created() {
      this.couponCode = this.$route.query.coupon || ''
    },
    methods: {
      closeDialog() {
        this.$emit('close')
      },
      setAmountTotals(amount) {
        this.errors = { ...this.errors, amount: [] }
        this.amount = amount
        this.updateTotals()
      },
      async updateTotals() {
        this.isLoading = true
        const params = {
          amount: this.amount,
          payment_method_id: this.paymentMethod.id,
          ...(this.couponCode && { coupon_code: this.couponCode })
        }
        try {
          const response = await invoicedRepository.totals(params)
          this.totals = {
            bonus: response.bonus,
            bonusPercent: response.bonus_percent,
            coupon: {
              bonusAmount: response.coupon?.bonus_amount,
              bonusPercentage: response.coupon?.bonus_percentage,
              bonusType: response.coupon?.bonus_type
            },
            couponBonus: response.coupon_bonus ?? 0,
            fee: response.fee,
            feePercent: response.fee_percent * 100,
            toBeCredited: response.to_be_credited,
            totalAmount: response.total_amount
          }
          this.errors = {}
        } catch (error) {
          this.totals = {
            ...this.totals,
            couponBonus: 0,
            fee: 0,
            feePercent: 0,
            bonus: null,
            totalAmount: 0
          }
          this.errors = handleErrors(error)
        }
        this.isLoading = false
      },
      async submit(recaptcha) {
        this.invoicedIsLoading = true
        const params = {
          'g-recaptcha-response': recaptcha,
          amount: this.amount,
          payment_method_id: this.paymentMethod.id,
          google_analytics_client_id: await getGoogleAnalyticsClientId(this.measurementId),
          google_analytics_session_id: await getGoogleAnalyticsSessionId(this.measurementId),
          coupon_code: this.couponCode,
          ...(this.shift4Strategy && { enable_autopayment: this.autoPayment })
        }
        try {
          const invoice = await invoicedRepository.store(params)
          this.invoice = {
            amount: invoice.amount,
            formParams: invoice.form_params ?? {},
            paymentMethod: new PaymentMethod(invoice.payment_method),
            metadata: invoice.metadata ?? {}
          }
          gtmPush({
            event: GTM_EVENTS.PROCCED_TO_BILLING,
            event_category: GTM_EVENTS_CATEGORY.REVENUE,
            custom_map: { dimension6: 'Country B' },
            'Country B': this.invoice.metadata.country_iso_code_3
          })
          gtmPush({
            event: GTM_EVENTS.INVOICE_CREATED,
            event_category: GTM_EVENTS_CATEGORY.REVENUE,
            invoice_id: invoice.id,
            short_name: this.paymentMethod.type
          })
          if (this.paymentMethodIsManual) {
            this.manualDialog = true
            return
          }
          if (this.paymentMethodIsStripeCheckout && this.adNetwork.settings.stripeRedirectUrl) {
            const queryString = stringify(this.invoice.formParams)
            window.open(`${this.adNetwork.settings.stripeRedirectUrl}?${queryString}`, '_blank')
            return
          }
          const { strategy } = this.invoice.paymentMethod
          const strategyResponse = await getPaymentStrategy(strategy)(this.invoice.formParams)
          if (this.canEnableAutoPayment && this.autoPayment) {
            await this.subscriptionAutoPayments(this.invoice, strategyResponse.customer.id)
            gtmPush({
              event: GTM_EVENTS.AUTOPAYMENT_ON,
              event_category: GTM_EVENTS_CATEGORY.PAYMENT
            })
          }
          if (strategyResponse?.customer?.id) {
            this.$router.push({ name: routeNames.ADD_FUNDS_SUCCESS })
          }
          if (strategyResponse?.qrCode) {
            this.crCodeImage = strategyResponse.qrCode
          } else {
            this.closeDialog()
          }
        } catch (error) {
          gtmPush({ event: GTM_EVENTS.PAYMENT_ERROR })
          this.errors = handleErrors(error)
        } finally {
          this.invoicedIsLoading = false
        }
      },
      async subscriptionAutoPayments(customerId) {
        const params = {
          payment_method_id: this.invoice.paymentMethod.id,
          charge_amount: this.invoice.amount,
          customer_data: {
            customer_id: customerId
          }
        }
        try {
          await invoicedRepository.subscription(params)
        } catch (error) {
          handleErrors(error)
        }
      },
      toFixedByDefault
    }
  }
</script>

<style scoped lang="scss">
:deep(.grecaptcha-badge) {
  visibility: hidden;
  width: 0 !important;
}
.address {
  overflow-wrap: anywhere;
}
</style>
